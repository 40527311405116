import React, {useEffect, useRef, useState} from 'react'
import {Canvas, useFrame, useThree} from '@react-three/fiber'
import * as THREE from 'three';
import fragmentShader from '../shaders/FragmentShader';
import vertexShader from '../shaders/VertextShader';

import StarTexturePath from '../images/particle.webp'
import {Scroll, ScrollControls, useScroll} from "@react-three/drei";


function lerp(a, b, t) {
    return a * (1 - t) + b * t;
}

function ScrollContainer({scroll, children}) {
    const {viewport} = useThree();
    const group = useRef();
    let last = scroll.current;
    useFrame((state, delta) => {
        if (scroll.current !== last) {
            if (scroll.current > last) {
                state.camera.position.z -= (scroll.current * 0.02);
                state.camera.rotation.z -= (scroll.current * 0.01);
            } else {
                state.camera.position.z += (scroll.current * 0.02);
                state.camera.rotation.z += (scroll.current * 0.01);
            }
            last = scroll.current;
        }
        console.log(scroll.current);
        //console.log(group.current);
        //console.log(THREE.MathUtils.damp(group.current.position.y, viewport.height * scroll.current, 4, delta));
        //group.current.position.y = THREE.MathUtils.damp(group.current.position.y, viewport.height * scroll.current, 4, delta)
        // Or: group.current.position.lerp(vec.set(0, viewport.height * scroll.current, 0), 0.1)
    })
    return <group ref={group}>{children}</group>
}

// TODO: Si no lo termino usando matarlo
// Camera path
const cameraPositionCurve = new THREE.CatmullRomCurve3([
    // 1ra escena
    new THREE.Vector3(0.111, 0.11, 5.0),
    // 2da escena
    new THREE.Vector3(-2, 0.35, 5.0), // Partners point
    new THREE.Vector3(-2, 0.12, 4.8), // Phone point
    // 3ra escena
    new THREE.Vector3(0.32, 0.8, 4.9),
    new THREE.Vector3(0.32, 1.5, 4.9)
]);

export default function HomeSpace(props) {

    const canvasStyle = {
        position: 'fixed',
        display: 'block',
        top: 0,
        left: 0,
        width: '100vw',
    }
    //
    // const [scrollHeight, setScrollHeight] = useState(0);
    //
    // useEffect(() => {
    //     function handleScroll(event) {
    //         setScrollHeight(window.scrollY);
    //         // console.log(window.scrollY);
    //         // console.log(window.innerHeight);
    //         // console.log(mainRef.current.clientHeight);
    //     }
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    return (
        <Canvas
            onCreated={props.onCreated}
            style={canvasStyle}
            dpr={Math.min(window.devicePixelRatio, 2)}
            gl={{
                antialias: true,
                toneMapping: THREE.NoToneMapping,
                physicallyCorrectLights: true,
                outputEncoding: THREE.sRGBEncoding,
                invalidateFrameLoop: true,
                pixelRatio: window.devicePixelRatio
            }}
            camera={{position: [0, 0, 1], rotation: [1, 0, -0.5]}}
        >
            <color attach="background" args={["#000113"]}/>
            <ScrollContainer scroll={props.scroll}>
                <ScrollableSpace/>
            </ScrollContainer>
        </Canvas>
    );
}

function ScrollableSpace() {
    const addObject = (op) => {
        let count = 2000;
        const materials = [];

        let min_radius = op.min_radius;
        let max_radius = op.max_radius;

        let particlegeo = new THREE.PlaneBufferGeometry(1, 1);
        let geo = new THREE.InstancedBufferGeometry();
        geo.instanceCount = count;
        geo.setAttribute('position', particlegeo.getAttribute('position'));
        geo.index = particlegeo.index;
        let pos = new Float32Array(count * 3);
        for (let i = 0; i < count; i++) {
            let theta = Math.random() * 2 * Math.PI;
            let max_rad = lerp(max_radius - 0.1, max_radius + 0.1, Math.random());
            let min_rad = lerp(min_radius - 0.1, min_radius + 0.1, Math.random());
            let r = lerp(min_rad, max_rad, Math.random())

            let x = r * Math.sin(theta)
            let y = (Math.random() - 0.5) * 0.1;
            let z = r * Math.cos(theta);

            pos.set([x, y + 0.8, z - 2], i * 3);
        }
        geo.setAttribute('pos', new THREE.InstancedBufferAttribute(pos, 3, false));
        const texture = new THREE.TextureLoader().load(StarTexturePath);
        let material = new THREE.ShaderMaterial({
            extensions: {
                derivatives: "#extension GL_DES_standard_derivatives: enable"
            },
            side: THREE.DoubleSide,
            depthTest: false,
            transparent: true,
            size: 0.001,
            uniforms: {
                uTexture: {value: texture},
                time: {type: "f", value: 1.0},
                uColor: {value: new THREE.Color(op.color)},
                resolution: {type: "v2", value: new THREE.Vector2()}
            },
            vertexShader: vertexShader(),
            fragmentShader: fragmentShader()
        });
        materials.push(material);
        return new THREE.Mesh(geo, material);
    }

    let options = [
        {
            min_radius: 0.4,
            max_radius: 2.4,
            color: '#ffc68e',
            size: 1
        },
        {
            min_radius: 0.7,
            max_radius: 2.2,
            color: '#258bcb',
            size: 1
        }
    ]

    const pointMesh1 = addObject(options[0]);
    const pointMesh2 = addObject(options[1]);

    return (
        <mesh>
            <primitive object={pointMesh1}/>
            <primitive object={pointMesh2}/>
        </mesh>
    );
}