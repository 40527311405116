import {useLayoutEffect, useRef} from "react";
import { gsap } from "gsap";

export function MouseIcon() {
    const wheelRef = useRef();

    useLayoutEffect(() => {
        gsap.to(wheelRef.current, {
            duration: 1.3,
            y: "+=15px",
            yoyo: true,
            repeat: -1,
            ease: "power2.easeOut"
        });

    }, []);

    return (
        <svg className="mouse-scroll" width="38" height="66" viewBox="0 0 38 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="35" height="63" rx="17.5" stroke="white" strokeWidth="3"/>
            <path ref={wheelRef} d="M19.5 17.5L19.5 28.5" stroke="white" strokeWidth="4" strokeLinecap="round"/>
        </svg>
    )
}