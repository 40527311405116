import {useLayoutEffect, useRef} from "react";
import '../index.css';
import '../styles/media-queries.css';
import { gsap } from "gsap";

export function CircleProjects() {
    const wheelRef = useRef();

    return (
        <svg ref={wheelRef} width="420" height="770" viewBox="0 0 420 770" fill="none" xmlns="http://www.w3.org/2000/svg" className="orbit-circle-menu">
            <circle cx="-181" cy="377" r="600.5" stroke="white" stroke-dasharray="2 8"/>
        </svg>

    )
}