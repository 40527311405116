import Menu from "../components/Menu";

import SSF1Path from '../images/SSF1.png';
import SSF2Path from '../images/SSF2.png';
import SSFLogoPath from '../images/SSF_logo.svg';
import SSF3D from '../images/ssf3D.png';
import ProjectSpace from "../components/ProjectSpace";
import {CollaboratorBox} from "../components/CollaboratorBox";
import NextProjectBtn from "../components/buttons/NextProjectBtn";
import {useEffect} from "react";

function SSFGallery() {
    return (<>
    <div className="img-container">
        <img className="imageVerticalProject" src={SSF1Path} alt="SSF1"/>
        <img className="imageVerticalProject" src={SSF2Path} alt="SSF2"/>
    </div>
    </>);
}

export default function SSFProject() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <main>
                <Menu sameView={false}/>
                <ProjectSpace modelName={'ssf'}/>
                <div className="introContainer">
                    <img className="project-logo" src={SSFLogoPath} alt="Sustentabilidad Sin Fronteras logo"/>
                    <p>
                        La calculadora de huella de carbono es una herramienta para
                        concientizar sobre el impacto de nuestro estilo de vida en el medioambiente.
                    </p>
                    <img className="main-project-img-horizontal" src={SSF3D} alt="Sustentabilidad Sin Fronteras"/>
                </div>
                <section className="projectContainer">
                    <div className="projectContent">
                        <div className="summaryParagraphContainer">
                            <p className="summaryParagraph">
                                La calculadora consiste en un formulario web donde, respondiendo una serie de preguntas, se calcula la huella del usuario. Al finalizar el cálculo, se ofrecen consejos para reducir las emisiones y la posibilidad de compensarlas apoyando proyectos de impacto ambiental positivo.
                            </p>
                        </div>

                        <div className="collaboratorsContainer">
                            <SSFGallery/>
                            <CollaboratorBox>
                                <p className="collaborationBoxBaseText">
                                    La organización Sustentabilidad Sin Fronteras nos convocó para desarrollar una solución que contribuya a la concientización sobre la huella de carbono pero que también ayude a difundir distintos proyectos de impacto ambiental positivo para reducirla.
                                </p>
                                <div className="collaborator">
                                    <p className="collaboratorParagraph">Sustentabilidad Sin Fronteras</p>
                                    <a className="grey collaboratorLink" href="https://www.sustentabilidadsf.org.ar/"
                                       target="_blank">sustentabilidadsf.org.ar</a>
                                </div>
                                <p className="collaborationBoxBaseText">
                                    es una fundación ONG sin fines de lucro conformada por un grupo de profesionales de diversas disciplinas que se unen para hacerle frente al cambio climático.
                                </p>
                            </CollaboratorBox>
                        </div>

                        <p className="text-more-info">
                            El desafío principal del proyecto fue construir una plataforma simple e intuitiva donde el usuario pudiera completar la información sobre sus consumos y sus hábitos sin perder el interés en el camino.
                        </p>

                        <p className="text-more-info">
                            Para poder lograrlo, dividimos el formulario en 3 etapas: “Medir, Reducir, Compensar”. Cada una de ellas cuenta tanto con preguntas como información sobre qué impacto generan cada una de nuestras acciones.
                        </p>

                        <iframe width="100%" height="600" src="https://www.youtube.com/embed/yQn8fqYPdag" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        <div className="links-container">
                            <div className="link-container">
                                <span>Calculá tu huella</span>
                                <a href="https://huelladecarbono.sustentabilidadsf.org.ar/">huelladecarbono. sustentabilidadsf.org.ar</a>
                            </div>
                            <div className="link-container">
                                <span>¿Qué es la huella de carbono? | Sustentabilidad Sin Fronteras</span>
                                <a href="youtube.com/watch?v=vLWrAaczM_E">youtube.com/watch?v=vLWrAaczM_E</a>
                            </div>
                        </div>

                    </div>

                    <NextProjectBtn project={'mora'}/>

                </section>
            </main>
        </>
    );
}