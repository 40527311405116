import {useLayoutEffect, useState} from "react";
import {gsap} from "gsap";
import {ArrowLeftIcon} from "../svgs/ArrowLeftIcon";
import {ArrowRightIcon} from "../svgs/ArrowRightIcon";
import VideoProgressBar from "./VideoProgressBar";

import {Draggable} from "gsap/Draggable";

export default function VideoCaroussel(props) {

    const [videoSelected, setVideoSelected] = useState(0);

    // useLayoutEffect(() => {
    //     gsap.registerPlugin(Draggable);
    //
    //     let slideDuration = 0.5;
    //     let wrap = true;
    //
    //     let slides = document.querySelectorAll(".slide");
    //     let progressWrap = gsap.utils.wrap(0, 1);
    //
    //     let numSlides = slides.length;
    //
    //     gsap.set(slides, {
    //         xPercent: i => i * 100
    //     });
    //
    //     let wrapX = gsap.utils.wrap(-100, (numSlides - 1) * 100);
    //
    //     let animation = gsap.to(slides, {
    //         xPercent: "+=" + (numSlides * 100),
    //         duration: 1,
    //         ease: "none",
    //         paused: true,
    //         repeat: -1,
    //         modifiers: {
    //             xPercent: wrapX
    //         }
    //     });
    //
    //     let proxy = document.createElement("div");
    //     let slideAnimation = gsap.to({}, {});
    //     let slideWidth = 0;
    //     let wrapWidth = 0;
    //
    //
    //     let draggable = new Draggable(proxy, {
    //         trigger: ".slides-container",
    //         onPress: updateDraggable,
    //         //onDrag: updateProgress,
    //         onThrowUpdate: updateProgress,
    //         snap: {
    //             x: snapX
    //         }
    //     });
    //
    //     function updateDraggable() {
    //         slideAnimation.kill();
    //         this.update();
    //     }
    //
    //     function animateSlides(direction) {
    //         slideAnimation.kill();
    //         let x = snapX(gsap.getProperty(proxy, "x") + direction * slideWidth);
    //
    //         slideAnimation = gsap.to(proxy, {
    //             x: x,
    //             duration: slideDuration,
    //             onUpdate: updateProgress
    //         });
    //     }
    //     function updateProgress() {
    //         animation.progress(progressWrap(gsap.getProperty(proxy, "x") / wrapWidth));
    //     }
    //
    //     function snapX(value) {
    //         let snapped = gsap.utils.snap(slideWidth, value);
    //         return wrap ? snapped : gsap.utils.clamp(-slideWidth * (numSlides - 1), 0, snapped);
    //     }
    //
    //     function resize() {
    //         var norm = (gsap.getProperty(proxy, "x") / wrapWidth) || 0;
    //
    //         slideWidth = slides[0].offsetWidth;
    //         wrapWidth = slideWidth * numSlides;
    //
    //         wrap || draggable.applyBounds({minX: -slideWidth * (numSlides - 1), maxX: 0});
    //
    //         gsap.set(proxy, {
    //             x: norm * wrapWidth
    //         });
    //
    //         animateSlides(0);
    //         slideAnimation.progress(1);
    //     }
    //
    //     if (numSlides > 0){
    //         resize();
    //         window.addEventListener("resize", resize);
    //     }
    //
    // }, []);

    const arrowBtn = (direction) => {
        const onClick = () => {
            const movement = direction === 'left' ? -1 : +1;
            const videoToShow = videoSelected+movement;
            if (videoToShow >= 0 && videoToShow < videos.length) {
                setVideoSelected(videoToShow);
            }
        }
        return (
            <a href="#project-menu" className="slides-arrow-btn" onClick={onClick}>
                {direction === 'left' ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
            </a>
        )
    }

    const videos = [
        "https://www.youtube.com/embed/nx2fH6yew1A",
        "https://www.youtube.com/embed/VbXCCnpZB0A",
        "https://www.youtube.com/embed/9GSACYtk8bs",
        "https://www.youtube.com/embed/3w_8WfwKk-A",
        "https://www.youtube.com/embed/o7fDTf0Me3I"
    ];

    const style = {
        row: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

    }

    return (
        <>
            <div className="slides-container">
                <div className="arrowBtnContainer">
                    {arrowBtn('left')}
                    {arrowBtn('right')}
                </div>
                <div className="slides-inner">
                    {videos.map(function(videoUrl, index){
                        if (videoSelected === index) {
                            return (
                                <div className="slide" >
                                    <iframe width="1055" height="600" src={videoUrl}
                                            title="MORA" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen></iframe>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
            <VideoProgressBar amountOfVideos={videos.length} selectedVideo={videoSelected}/>
        </>
    )

}