import {useState} from 'react';
import {MenuMobileIcon} from "../svgs/MenuMobileIcon";
import {MisionIcon} from "../svgs/MisionIcon";
import {MisionBtn, ProjectsBtn} from "./Menu";
import ContactUs from "./ContactUs";


function MenuMobileContent(props) {
    return (
        <section className="contact-us-container" id="contact-us-id">
            <div className="contact-us-info-mobile">
                <MisionBtn btnId={'mision-btn-mobile'} sameView={true} onClick={props.onClose}/>
                <ProjectsBtn btnId={'project-btn-mobile'} sameView={true} onClick={props.onClose}/>
                <ContactUs btnId={'contact-us-mobile'}/>
            </div>
            <button onClick={props.onClose} className="go-back-btn" id="close-contact-us-btn-id">
                volver
            </button>
        </section>
    );
}


export default function MenuMobile() {

    const [isOpen, setModalView] = useState(false);

    if (isOpen) {
        return <MenuMobileContent onClose={() => setModalView(false)}/>
    } else {
        return (
            <button id="open-menu-mobile" className="fixed-icon-btn" onClick={() => setModalView(true)}>
                <MenuMobileIcon/>
            </button>
        );
    }
}