import LogoIconPath from '../images/logo.svg';
import ContactUs from './ContactUs';
import MenuMobile from './MenuMobile';
import {Link} from 'react-router-dom';
import {ProjectIcon} from "../svgs/ProjectIcon";
import {MisionIcon} from "../svgs/MisionIcon";

export function ProjectsBtn(props) {
    const btnId = props.btnId ? props.btnId : "projects-btn";
    if (props.sameView) {
        return (
            <a id={btnId} href="#project-menu" className="fixed-icon-btn" onClick={props.onClick}>
                <ProjectIcon/>
                <h2>Proyectos</h2>
            </a>
        );
    } else {
        return (
            <Link id={btnId} className="fixed-icon-btn" to={'/'}>
                <ProjectIcon/>
                <h2>Proyectos</h2>
            </Link>
        )
    }
}

export function MisionBtn(props) {
    const btnId = props.btnId ? props.btnId : "mision-btn-id";
    if (props.sameView) {
        return (
            <a id={btnId} href="#start" className="fixed-icon-btn" onClick={props.onClick}>
                <MisionIcon/>
                <h2>Misión</h2>
            </a>
        );
    } else {
        return (
            <Link id={btnId} className="fixed-icon-btn" to={'/'}>
                <MisionIcon/>
                <h2>Misión</h2>
            </Link>
        )
    }
}


export default function Menu(props) {

    const projectHref = "#project-menu"

    return (
        <div style={{height:'100vh', position:'fixed', zIndex: 100}}>
            <div id="logo" >
                <img id="logo-fixed-id" className="project-image" src={LogoIconPath} alt="logo"/>
            </div>

            <ProjectsBtn sameView={props.sameView}/>
            <ContactUs/>
            <MenuMobile/>
            <MisionBtn sameView={props.sameView}/>

        </div>
    );
}