import Menu from "../components/Menu";

import AuroraLogoPath from '../images/aurora_logo.svg';
import aurora3D from '../images/aurora3D.png';
import ProjectSpace from "../components/ProjectSpace";
import AuroraImagePath from '../images/aurora_foto.jpg';
import {CollaboratorBox} from "../components/CollaboratorBox";
import NextProjectBtn from "../components/buttons/NextProjectBtn";

import VideoCaroussel from "../components/VideoCaroussel";
import {useEffect} from "react";

export default function AuroraProject() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>

            <Menu sameView={false}/>
            /*<ProjectSpace modelName={'aurora'}/>*/
            <div className="introContainer">
                <img className="project-logo" src={AuroraLogoPath} alt="Aurora logo"/>
                <p>
                    Aurora es un proyecto que consistió en conseguir imágenes y mediciones de la estratósfera.
                </p>
            <div className="container-aurora-img-vertical">
                <img className="main-project-img-vertical" src={aurora3D} alt="Aurora"/>
                </div>
            </div>

            <div className="projectContainer">
                <div className="projectContent">
                    <div className="summaryParagraphContainer">
                        <p className="summaryParagraph">
                            Nuestra misión fue llevar cámaras y distintos elementos de medición al espacio, a una altura de 21 kilómetros,
                            utilizando un globo meteorológico y una computadora especialmente diseñada para este fin.
                        </p>
                    </div>

                    <div className="collaboratorsContainer">
                        <div className="collaboratorsImagesContainer">

                        </div>
                        <img src={AuroraImagePath} className="imageHorizontalProject" alt="Aurora en el espacio"/>
                        <CollaboratorBox>
                            <p className="collaborationBoxBaseText">
                                Para poder llevar a cabo el proyecto recibimos la colaboración de Christian Schiegelow (físico) y Jose Bini (cofundador de Bierhaus),
                                quienes ya habían realizado una experiencia similar.
                                Además, fue fundamental formar parte de comunidades afines como Servet y Lorawan Latam.
                            </p>
                        </CollaboratorBox>
                    </div>

                    <p className="text-more-info">
                        El desafío más grande fue llevar adelante un proyecto en el que se requerían conocimientos específicos de distintas disciplinas con las que
                        no estamos familiarizados: meteorología, física, electrónica, entre otras.
                    </p>

                    <p className="text-more-info">
                        Como desafíos más puntuales podemos mencionar el armado de la caja, la utilización de la sonda meteorológica, la recopilación de datos e
                        imágenes en condiciones adversas y a distancia.
                    </p>

                    <div className="base-scroll">
                        <VideoCaroussel/>
                    </div>

                    <div className="links-container">
                        <div className="link-container">
                            <span>Aurora en la liga de la ciencia</span>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=C-MVdrkHwWQ&t=2s">Ver video</a>
                        </div>
                        <div className="link-container">
                            <span>Medium - Cómo mandar algo al espacio</span>
                            <a target="_blank" rel="noreferrer" href="https://medium.com/eryxcoop/c%C3%B3mo-mandar-algo-al-espacio-e59f25cfff40">Leer artículo en Medium</a>
                        </div>
                        <div className="link-container">
                            <span>Medium - Cómo medir cosas en el espacio</span>
                            <a target="_blank" rel="noreferrer" href="https://medium.com/eryxcoop/c%C3%B3mo-medir-cosas-en-el-espacio-cb6d1ae8bb6c">Leer artículo en Medium</a>
                        </div>
                        <div className="link-container">
                            <span>Librería Github</span>
                            <a target="_blank" rel="noreferrer" href="https://github.com/eryxcoop/eventy">ver código</a>
                        </div>
                    </div>

                    <NextProjectBtn project={'fadu-viva'}/>
                </div>
            </div>

        </main>
    )
}
