export function AstronautsIcon() {

    const style = {
        position: 'absolute',
        marginTop: '-45px',
        background: '#000113',
        alignSelf: 'center',
        justifySelf: 'center',
        height: '40px',
        width: '100px',
    }

    return (
        <svg style={style} width="57" height="37" viewBox="0 0 57 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_411_2)">
                <path
                    d="M25.0724 8.62842H26.2673C27.4934 8.62842 28.4805 9.62927 28.4805 10.8724V12.3052C28.4805 13.5484 27.4934 14.5493 26.2673 14.5493H25.1036"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M3.92763 8.62842H2.73272C1.50663 8.62842 0.519531 9.62927 0.519531 10.8724V12.3052C0.519531 13.5484 1.50663 14.5493 2.73272 14.5493H3.89646"
                    stroke="white" stroke-miterlimit="10"/>
                <path d="M0.519531 37V30.2047C0.519531 26.707 2.71194 23.736 5.77715 22.6087L6.1512 22.5455"
                      stroke="white" stroke-miterlimit="10"/>
                <path d="M28.4805 37V30.2047C28.4805 26.8018 26.4024 23.8835 23.4514 22.693L22.9943 22.5771"
                      stroke="white" stroke-miterlimit="10"/>
                <path d="M27.5349 18.8266L27.5869 26.3067" stroke="white" stroke-miterlimit="10"/>
                <path d="M24.0749 16.9619H25.6958C26.7141 16.9619 27.5453 17.7941 27.5453 18.8371V20.881" stroke="white"
                      stroke-miterlimit="10"/>
                <path d="M1.6521 20.8178V18.8371C1.6521 17.8047 2.47295 16.9619 3.50162 16.9619H4.89395" stroke="white"
                      stroke-miterlimit="10"/>
                <path d="M1.70405 25.9695L1.6521 19.1005" stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M3.61591 11.5889C3.61591 5.47839 8.54102 0.526794 14.5675 0.526794C20.5941 0.526794 25.436 5.47839 25.436 11.5889C25.436 17.6993 20.5941 22.6509 14.5675 22.6509C8.54102 22.6509 3.61591 17.6993 3.61591 11.5889Z"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M23.2229 18.4368C23.8255 19.0373 24.1684 19.7115 24.1684 20.4174C24.1684 22.9248 19.7732 24.9581 14.5675 24.9581C9.36188 24.9581 5.30957 22.9248 5.30957 20.4174C5.30957 19.7537 5.6109 19.111 6.1512 18.5421"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M3.61591 11.0621C3.61591 7.30097 8.54102 4.24573 14.5675 4.24573C20.5941 4.24573 25.436 7.30097 25.436 11.0621C25.436 14.8232 20.5941 17.8784 14.5675 17.8784C8.54102 17.8784 3.61591 14.8232 3.61591 11.0621Z"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M21.6747 28.7614H7.3565C6.68509 28.7614 6.14081 29.3133 6.14081 29.994V34.6823C6.14081 35.363 6.68509 35.9149 7.3565 35.9149H21.6747C22.3461 35.9149 22.8904 35.363 22.8904 34.6823V29.994C22.8904 29.3133 22.3461 28.7614 21.6747 28.7614Z"
                    stroke="white" stroke-miterlimit="10"/>
            </g>
            <g clip-path="url(#clip1_411_2)">
                <path
                    d="M53.0724 8.62842H54.2673C55.4934 8.62842 56.4805 9.62927 56.4805 10.8724V12.3052C56.4805 13.5484 55.4934 14.5493 54.2673 14.5493H53.1036"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M31.9276 8.62842H30.7327C29.5066 8.62842 28.5195 9.62927 28.5195 10.8724V12.3052C28.5195 13.5484 29.5066 14.5493 30.7327 14.5493H31.8965"
                    stroke="white" stroke-miterlimit="10"/>
                <path d="M28.5195 37V30.2047C28.5195 26.707 30.7119 23.736 33.7771 22.6087L34.1512 22.5455"
                      stroke="white" stroke-miterlimit="10"/>
                <path d="M56.4805 37V30.2047C56.4805 26.8018 54.4024 23.8835 51.4514 22.693L50.9943 22.5771"
                      stroke="white" stroke-miterlimit="10"/>
                <path d="M55.5349 18.8266L55.5869 26.3067" stroke="white" stroke-miterlimit="10"/>
                <path d="M52.0749 16.9619H53.6958C54.7141 16.9619 55.5453 17.7941 55.5453 18.8371V20.881" stroke="white"
                      stroke-miterlimit="10"/>
                <path d="M29.6521 20.8178V18.8371C29.6521 17.8047 30.473 16.9619 31.5016 16.9619H32.8939" stroke="white"
                      stroke-miterlimit="10"/>
                <path d="M29.7041 25.9695L29.6521 19.1005" stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M31.6159 11.5889C31.6159 5.47839 36.541 0.526794 42.5675 0.526794C48.5941 0.526794 53.436 5.47839 53.436 11.5889C53.436 17.6993 48.5941 22.6509 42.5675 22.6509C36.541 22.6509 31.6159 17.6993 31.6159 11.5889Z"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M51.2229 18.4368C51.8255 19.0373 52.1684 19.7115 52.1684 20.4174C52.1684 22.9248 47.7732 24.9581 42.5675 24.9581C37.3619 24.9581 33.3096 22.9248 33.3096 20.4174C33.3096 19.7537 33.6109 19.111 34.1512 18.5421"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M31.6159 11.0621C31.6159 7.30097 36.541 4.24573 42.5675 4.24573C48.5941 4.24573 53.436 7.30097 53.436 11.0621C53.436 14.8232 48.5941 17.8784 42.5675 17.8784C36.541 17.8784 31.6159 14.8232 31.6159 11.0621Z"
                    stroke="white" stroke-miterlimit="10"/>
                <path
                    d="M49.6747 28.7614H35.3565C34.6851 28.7614 34.1408 29.3133 34.1408 29.994V34.6823C34.1408 35.363 34.6851 35.9149 35.3565 35.9149H49.6747C50.3461 35.9149 50.8904 35.363 50.8904 34.6823V29.994C50.8904 29.3133 50.3461 28.7614 49.6747 28.7614Z"
                    stroke="white" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_411_2">
                    <rect width="29" height="37" fill="white"/>
                </clipPath>
                <clipPath id="clip1_411_2">
                    <rect width="29" height="37" fill="white" transform="translate(28)"/>
                </clipPath>
            </defs>
        </svg>


    );
}