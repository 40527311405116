import HomeSpace from '../components/HomeSpace';
import Menu from '../components/Menu';
import LogoIconPath from '../images/logo.svg';
import '../index.css';
import ProjectsMenu from '../components/ProjectsMenu';
import {useRef} from "react";
import {MouseIcon} from "../svgs/MouseIcon";
import HandIcon from "../images/hand-icon-mobile.svg";
import {gsap, Power1} from "gsap";
import '../styles/media-queries.css';

import {useIntersection} from "react-use";


export default function Home() {
    const scrollRef = useRef();
    const sectionRef = useRef();
    const sectionRef2 = useRef();
    const sectionRef3 = useRef();
    const sectionRef4 = useRef();
    const scroll = useRef(0);

    const intersection = useIntersection(sectionRef, {
        root: null,
        rootMargin: "0px",
        threshold: 0.7
    })

    const intersection2 = useIntersection(sectionRef2, {
        root: null,
        rootMargin: "0px",
        threshold: 0.7
    })

    const intersection3 = useIntersection(sectionRef3, {
        root: null,
        rootMargin: "0px",
        threshold: 0.7
    })

    const intersection4 = useIntersection(sectionRef4, {
        root: null,
        rootMargin: "0px",
        threshold: 0.7
    })
    const fadeIn = (element) => {
        gsap.to(element, 1, {
            opacity: 1,
            y: -60,
            ease: Power1.easeOut,
            stagger: {
                amount: 0.3
            }
        })
    }
    const fadeOut = (element) => {
        gsap.to(element, 1, {
            opacity: 0,
            y: -20,
            ease: Power1.easeOut,
        })
    }

    intersection && intersection.intersectionRatio < 0.7 ? fadeOut(".fadeIn") : fadeIn(".fadeIn");
    intersection2 && intersection2.intersectionRatio < 0.7 ? fadeOut(".fadeIn2") : fadeIn(".fadeIn2");
    intersection3 && intersection3.intersectionRatio < 0.7 ? fadeOut(".fadeIn3") : fadeIn(".fadeIn3");
    intersection4 && intersection4.intersectionRatio < 0.7 ? fadeOut(".fadeIn4") : fadeIn(".fadeIn4");


    return (
        <>
            <HomeSpace scroll={scroll} onCreated={(state) => state.events.connect(scrollRef.current)}/>
            <Menu sameView={true}/>
            <main
                className="scroll"
                ref={scrollRef}
                onScroll={(e) => (scroll.current = e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight))}
            >
                <section className="page header" id="start">
                    <div className="logo-container">
                        <img className="logo" src={LogoIconPath} alt="logo" id="logo-start-id"/>
                    </div>
                    <p>
                        Somos un espacio interdisciplinario de investigación, desarrollo e innovación tecnológica dentro de Eryx.
                    </p>
                    <div className="arrow-container" id="arrow-down-id">
                        <MouseIcon/>
                        <img className="hand-scroll" src={HandIcon} alt="icono de scroll"/>
                    </div>
                </section>

                <section className="page first">
                    <div className="elem prim" ref={sectionRef}>
                        <h2 className='fadeIn'>Exploración</h2>
                        <p className='fadeIn'>
                            Exploramos distintos proyectos, ideas o iniciativas que involucren tecnología en algún punto.
                        </p>
                    </div>
                </section>

                <section className="page">
                    <div className="elem prim" ref={sectionRef2}>
                        <h2 className='fadeIn2'>Intercambio</h2>
                        <p className='fadeIn2'>
                            Nos conectamos con especialistas en aquellas disciplinas que potencien la solución de los problemas que atacamos.
                        </p>
                    </div>
                </section>

                <section className="page">
                    <div className="elem prim" ref={sectionRef3}>
                        <h2 className='fadeIn3'>Impacto Social</h2>
                        <p className='fadeIn3'>
                            Buscamos identificar problemáticas sociales que puedan ser resueltas
                            a través de soluciones tecnológicas y disruptivas. </p>
                    </div>
                </section>

                <section className="page">
                    <div className="elem prim" ref={sectionRef4}>
                        <p className='fadeIn4'>
                            Nuestra búsqueda es innovar a través de la exploración y el descubrimiento.
                        </p>
                        <h3 className="fadeIn4">BIENVENIDOS A LABS</h3>
                    </div>
                </section>

                <ProjectsMenu/>
            </main>
        </>
    );
}