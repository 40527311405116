import React, {Suspense, useRef} from 'react'
import {Canvas, useFrame, useLoader} from '@react-three/fiber'
import * as THREE from 'three';
import fragmentShader from '../shaders/FragmentShader';
import vertexShader from '../shaders/VertextShader';
import AuroraModelPath from '../models/aurora_low.glb';
import MoraModelPath from '../models/astronauta_low.glb';
import SSFModelPath from '../models/ssf.glb';
import FaduVivaPath from '../models/faduviva-color.glb';


import StarTexturePath from '../images/particle.webp'
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {OrbitControls} from "@react-three/drei";


function lerp(a, b, t) {
    return a * (1 - t) + b * t;
}

function Model(props) {
    const objectRef = useRef();

    const names = {
        mora: [MoraModelPath, [6, 4, -8] , [45,-45, 6]], // path position rotation
        aurora: [AuroraModelPath, [6, 4, -8] , [45,-45, 6]], // path position rotation
        fadu: [FaduVivaPath, [12, 18, -8] , [45, -45, -45]], // path position rotation
        ssf: [SSFModelPath, [8, 25, -8] , [45, -45, -45]], // path position rotation
    }
    const modelInfo = names[props.modelName];
    const modelPath = modelInfo[0];
    const position = modelInfo[1];
    const rotation = modelInfo[2];

    useFrame(({ clock }) => {
        const time = clock.getElapsedTime();
        objectRef.current.position.z = Math.cos(time) * 0.3 - 8;
        //objectRef.current.rotation.y = time * -0.1;
        //objectRef.current.rotation.y = time * -0.1;
        //objectRef.current.rotation.x = 90 ;
    });

    const gltf = useLoader(GLTFLoader, modelPath, loader => {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
        loader.setDRACOLoader(dracoLoader)
    }, (e) => console.log(e));

    return (
        <Suspense fallback={null}>
            <primitive ref={objectRef} object={gltf.scene} position={position} rotation={rotation}/>
        </Suspense>
    );
}


export default function ProjectSpace(props) {
    const canvasStyle = {
        display: 'block',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        outline: 'none'
    }

    const addObject = (op) => {
        let count = 1000;
        const materials = [];

        let min_radius = op.min_radius;
        let max_radius = op.max_radius;

        let particlegeo = new THREE.PlaneBufferGeometry(1, 1);
        let geo = new THREE.InstancedBufferGeometry();
        geo.instanceCount = count;
        geo.setAttribute('position', particlegeo.getAttribute('position'));
        geo.index = particlegeo.index;
        let pos = new Float32Array(count * 3);
        for (let i = 0; i < count; i++) {
            let theta = Math.random() * 2 * Math.PI;
            let max_rad = lerp(max_radius - 0.1, max_radius + 0.1, Math.random());
            let min_rad = lerp(min_radius - 0.1, min_radius + 0.1, Math.random());
            let r = lerp(min_rad, max_rad, Math.random())

            let x = r * Math.sin(theta)
            let y = (Math.random() - 0.5) * 0.1;
            let z = r * Math.cos(theta);

            pos.set([x, y + 2, z - 0.5], i * 3);
        }
        geo.setAttribute('pos', new THREE.InstancedBufferAttribute(pos, 3, false));
        const texture = new THREE.TextureLoader().load(StarTexturePath);
        let material = new THREE.ShaderMaterial({
            extensions: {
                derivatives: "#extension GL_DES_standard_derivatives: enable"
            },
            side: THREE.DoubleSide,
            depthTest: false,
            transparent: true,
            size: 0.001,
            uniforms: {
                uTexture: {value: texture},
                time: {type: "f", value: 1.0},
                uColor: {value: new THREE.Color(op.color)},
                resolution: {type: "v2", value: new THREE.Vector2()}
            },
            vertexShader: vertexShader(),
            fragmentShader: fragmentShader()
        });
        materials.push(material);
        return new THREE.Mesh(geo, material);
    }

    let options = [
        {
            min_radius: 0.2,
            max_radius: 3.4,
            color: '#f7b373',
            size: 1
        },
        {
            min_radius: 0.7,
            max_radius: 5.2,
            color: '#0081CB',
            size: 1
        }
    ]

    const pointMesh1 = addObject(options[0]);
    const pointMesh2 = addObject(options[1]);
    let cameraDataAurora = {position: [0, 0, 1], rotation: [1, 0, -0.5]};

    return (
        <div id="canvas-container">
            <Canvas
                style={canvasStyle}
                dpr={Math.min(window.devicePixelRatio, 2)}
                gl={{
                    antialias: true,
                    toneMapping: THREE.NoToneMapping,
                    physicallyCorrectLights: true,
                    outputEncoding: THREE.sRGBEncoding,
                    invalidateFrameLoop: true,
                }}
                camera={cameraDataAurora}
            >
                <color attach="background" args={["#000113"]}/>
                <ambientLight />
                <directionalLight color="white" position={[5, 0, 5]} />
                <mesh>
                    <primitive object={pointMesh1} position={cameraDataAurora.position}/>
                    <primitive object={pointMesh2}/>
                </mesh>
                <Model modelName={props.modelName}/>
            </Canvas>
        </div>
    );
}

