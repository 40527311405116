export function ArrowLeftIcon() {

    return (
        <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17.3335 30.6663L2.66726 16.0001L17.3335 1.33382L15.9997 6.99385e-07L-0.000380262 16.0001L15.9997 32.0001L17.3335 30.6663Z"
                  fill="#D9D9D9"/>
        </svg>


    );
}