export function ContactIcon() {

    return (
        <svg className="iconContact" viewBox="0 0 46 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="spaceship" class="st0" d="M40.5,10.1l-1-1.1h-10l-1.2-1.8h-2.5l0,0l0,0h-1.5V0h-4.9v7.2h-1l-1.2,1.5v2h-1.6l-0.8,1.3v3.6l0.8,1.3h1.3v2.5
	l0.9,0.9h1.4l0.2,0.3H24v9.9h4.3v-9.9h0.5h0.9v4.5h5.5v-7.5h4.4l1-1.1c1.4-0.1,2.5-1.2,2.5-2.6v-1C43,11.3,41.9,10.2,40.5,10.1z
	 M28.5,9h-3.1l0.8-1h1.7L28.5,9z M20.1,0.8h3.3v6.4h-3.3V0.8z M15.6,15.2v-3.1l0.5-0.7h1.1v4.3l0.2,0.2h-1.3L15.6,15.2z M17.7,18.9
	V17l0.4-0.4h0.5v2.8h-0.5L17.7,18.9z M19.1,15.7h-0.8L18,15.4V8.9L18.7,8h0.6h4.9h0.9l-0.8,1l0,0v6.3h-5L19.1,15.7z M19.8,19.6
	l-0.3-0.5v-2.5l0.3-0.5h8.5l-0.2,0.3v2.9l0.2,0.3H24H19.8z M27.5,29.6h-2.7v-9.1h2.7V29.6z M28.9,19.1v-2.5l0.3-0.5h0.5h0.6l0.4,0.5
	v2.4l-0.4,0.5h-0.6h-0.5L28.9,19.1z M34.3,24.1h-3.8v-3.7h0.2l0.8-1.1v-1.9h2.9V24.1z M31.4,16.6v-0.2l-0.8-1.1h-1h-0.9h-3.6V9.8h14
	l0.2,0.3h-1.6L37,10.8v4.9l0.7,0.7h1.6l-0.2,0.3H31.4z M42.2,13.7c0,1-0.8,1.9-1.9,1.9h-2.3l-0.3-0.2v-4.2l0.3-0.2h2.3
	c1,0,1.9,0.8,1.9,1.9V13.7z"/>
            <path id="fire3" class="st0" d="M12.2,11.3l1.2,1l-0.6,0.5l0.8,0.5h-3l2.8,0.8l-1.1,1.4l1-0.3L13,16.8l2.2-1.1l0.2-4.6l-2.7-1.5
	l1.1,1.7L12.2,11.3L12.2,11.3z M10,13.2l-1-0.5l-9,0.7l8.9,0.2L10,13.2z M11.9,11.8l-1.6-0.4l1.6,0.9V11.8z M12.2,10.4V9.9l-1.6-1.1
	l1.3,1.5L12.2,10.4z M11.7,14.6L11,14.5l-0.7,0.7l1.1-0.2L11.7,14.6z M4.7,18.4h7l0.8-0.2l-1.1-0.5L4.7,18.4z M14.7,16.8l1.1,0.7
	L13,18l2.9,0.5l-1.7,1l2.1-0.3L15,20.7l2-1.4v-2.6L14.7,16.8L14.7,16.8z"/>
            <path id="fire2" class="st0" d="M15,15l-0.1-2.8l-1.5-0.8l0.9,1.2L12,12.1l2,1.1l-3.7,0.7l3.8,0.2l-1.6,0.6l2.2-0.1L13,15.8L15,15z
	 M10,13.6l-0.5-0.4l-4.8,0.6L9.4,14L10,13.6z M11.6,14.6L10,15.4l1.6-0.4V14.6z M15.8,17.5l-1.9,0.3l2.5,0.4L15,18.9l1.8-0.3l-0.7,1
	l1.1-0.6v-2h-2.5L15.8,17.5z"/>
            <path id="fire1" class="st0" d="M15,15.8l0.1-4.3l-1.3-0.9l1,1.5l-1.3-0.4l0.9,1l-0.9,0.2l0.7,0.6l-1.9,0.3l1.8,0.6l-1.9,1.3
	l2.2-0.6l-0.5,2L15,15.8z M15.6,17l0.9,0.7l-2,0.6l1.7,0.2l-1,0.8l1.2-0.1L16.1,20l0.8-0.8v-2.3L15.6,17L15.6,17z M10,12.8l-2.9,0.4
	l2.8,0.4l0.6-0.4L10,12.8z M12.7,12.4l-0.6-0.5l-2.9,0.4l2.8,0.4L12.7,12.4z M10.6,18.8l2.6,0.2l0.5-0.2L13,18.3L10.6,18.8z"/>
        </svg>

    );
}