import { useState } from 'react';
import {ContactIcon} from "../svgs/ContactIcon";
import instagramIcon from '../svgs/instagram.svg';
import facebookIcon from '../svgs/facebook.svg';

function ContactUsBody(props) {
    return (
        <section className="contact-us-container" id="contact-us-id">
            <div className="contact-us-info">
            <h1>¡Contactanos!</h1>
            <p>Si querés ser parte de nuestros proyectos envianos un mail y nos pondremos en contacto pronto.</p>
            <a id="copy_to_clipboard" href="mailto:labs@eryx.com" className="mail-link">labs@eryx.com</a>
                <div className="media-icon-container">
                    <a href="https://www.instagram.com/eryxcoop/">
                        <img className="media-icon" src={instagramIcon} alt="instagram"/>
                    </a>
                    <a href="https://www.facebook.com/eryxcoop/">
                        <img className="media-icon" src={facebookIcon} alt="facebook"/>
                    </a>
                </div>
            <p className="ig-link">@eryxcoop</p>

            </div>
            <button onClick={props.onClose} className="go-back-btn" id="close-contact-us-btn-id">volver</button>
      </section>
    );
}


export default function ContactUs(props) {

    const [isOpen, setModalView] = useState(false);

    if (isOpen) {
        return <ContactUsBody onClose={() => setModalView(false)}/>
    } else {
        return (
            <button id={props.btnId ? props.btnId : "open-contact-us"} className="fixed-icon-btn" onClick={() => setModalView(true)}>
                <ContactIcon/>
                <h2>Contacto</h2>
            </button>
        );
    }
}