import {Link} from "react-router-dom";

export default function NextProjectBtn(props) {
    const style = {

    }
    return (
        <div>
            <Link className="nextProjectLink" to={'/' + props.project}>siguiente proyecto</Link>
        </div>
    )
}