import {FullCircleIcon} from "../svgs/FullCircleIcon";
import {EmptyCircleIcon} from "../svgs/EmptyCircleIcon";

export default function VideoProgressBar(props) {

    const amountOfVideos = props.amountOfVideos;
    const selectedVideo = props.selectedVideo;

    const style = {
        row: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        element: {
            marginLeft: '1rem'
        }
    }

    return (
        <div style={style.row} className="circleProgress">
            {
                [...Array(amountOfVideos).keys()].map(i => {
                    const icon  = selectedVideo === i ? <FullCircleIcon/> : <EmptyCircleIcon/>
                    return <div style={style.element}>{icon}</div>
                })
            }
        </div>
    )

}