import Menu from "../components/Menu";

import FaduVivaImagePath from '../images/fadu_viva.jpg';
import FaduVivaLogoPath from '../images/faduviva_logo.svg';
import fadu3D from '../images/fadu3D.png';
import {Link} from "react-router-dom";
import ProjectSpace from "../components/ProjectSpace";
import {CollaboratorBox} from "../components/CollaboratorBox";
import NextProjectBtn from "../components/buttons/NextProjectBtn";
import {useEffect} from "react";


export default function FaduVivaProject() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
        <main>
            <Menu sameView={false}/>
            <ProjectSpace modelName={'fadu'}/>
            <div className="introContainer">
                <img className="project-logo" src={FaduVivaLogoPath} alt="Fadu Viva logo"/>
                <p>
                    FADU Viva es una ventana a lo que sucede en Ciudad Universitaria en tiempo real.
                </p>
                <img className="main-project-img-horizontal" src={fadu3D} alt="FADU Viva"/>
            </div>
            <section className="projectContainer">
                <div className="projectContent">
                    <div className="summaryParagraphContainer">
                        <p className="summaryParagraph">
                            Se trata de una “postal animada” que rinde homenaje a la FADU, creada por diseñadores y programadores en conjunto. Su valor radica en la interacción entre elementos que brindan datos en tiempo real (APIs) con ilustraciones.
                        </p>
                    </div>

                    <div className="collaboratorsContainer">
                        <img src={FaduVivaImagePath} className="imageHorizontalProject" alt="FADU Viva"/>
                        <CollaboratorBox>
                            <p className="collaborationBoxBaseText">
                                Para poder llevar a cabo el proyecto, entender las necesidades específicas del usuario y las posiblidades que brinda el entorno, a la hora de realizar este desarrollo mantuvimos contacto con las autoridades directivas de la Facultad de Arquitectura, Diseño y Urbanismo.
                            </p>
                        </CollaboratorBox>
                    </div>

                    <p className="text-more-info">
                        El desafío principal del proyecto fue transformar una pieza artística como una ilustración en una herramienta para el usuario, en este caso, estudiantes de la Facultad de Arquitectura, Diseño y Urbanismo.

                        Para lograrlo, la combinamos con información de lo que sucede en un contexto determinado en tiempo real, brindando información sobre el tránsito, transporte y clima.
                    </p>

                    <iframe src="https://player.vimeo.com/video/575611349?h=59d6049df7" width="100%" height="700" frameborder="0" className="faduIframe" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                    <div className="links-container">
                        <div className="link-container">
                            <span>Para ver el proyecto ingrese a este link:</span>
                            <a href="https://fadu-viva.eryx.co/">fadu-viva.eryx.co</a>
                        </div>
                    </div>

                    <NextProjectBtn project={'ssf'}/>
                </div>
            </section>
        </main>
        </>
    );
}