export function MisionIcon() {

    return (
        <svg className="iconMision" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.6" y="14.8" class="st0" width="27.3" height="1"/>
            <path class="st0" d="M27,2.4h-4.8v1H27c0.8,0,1.4,0.6,1.4,1.4v3.3h1V4.8C29.3,3.5,28.3,2.4,27,2.4z M2.1,4.8c0-0.8,0.6-1.4,1.4-1.4
	h4.8v-1H3.4c-1.3,0-2.4,1.1-2.4,2.4v3.3h1V4.8z M2.1,25.6V22h-1v3.6c0,1.3,1.1,2.4,2.4,2.4h4.8v-1H3.4C2.7,27,2.1,26.3,2.1,25.6z
	 M28.3,25.6c0,0.8-0.6,1.4-1.4,1.4h-4.8v1H27c1.3,0,2.4-1.1,2.4-2.4V22h-1V25.6z M15.2,6.7c-0.7,0-1.3,0.1-1.9,0.2
	c-0.3-0.4-0.8-0.6-1.3-0.6c-0.9,0-1.7,0.8-1.7,1.7c0,0.1,0,0.2,0,0.3C8.3,9.8,7,12.2,7,14.9c0,4.5,3.7,8.2,8.2,8.2s8.2-3.7,8.2-8.2
	S19.7,6.7,15.2,6.7z M15.2,22.1c-4,0-7.2-3.2-7.2-7.2c0-2.3,1.1-4.3,2.8-5.6c0.3,0.3,0.7,0.5,1.2,0.5c0.9,0,1.7-0.8,1.7-1.7
	c0-0.1,0-0.1,0-0.2c0.5-0.1,1-0.2,1.6-0.2c4,0,7.2,3.2,7.2,7.2S19.1,22.1,15.2,22.1z M23.8,24.2c0.9,0,1.7-0.8,1.7-1.7
	c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C22.1,23.5,22.9,24.2,23.8,24.2z"/>
        </svg>

    );
}