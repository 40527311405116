import Menu from "../components/Menu";

import MoraLogoPath from '../images/mora_logo.svg';

import Mora1Path from '../images/mora1.png';
import Mora3Path from '../images/mora3.png';
import AstronautMORA from '../images/mora3D.png';
import {Link} from "react-router-dom";
import ProjectSpace from "../components/ProjectSpace";
import {CollaboratorBox} from "../components/CollaboratorBox";
import NextProjectBtn from "../components/buttons/NextProjectBtn";
import {useEffect} from "react";


function MoraGallery() {
    return (<>
            <div className="img-container">
                <img className="imageVerticalProject" src={Mora1Path} alt="mora1"/>
                <img className="imageVerticalProject" src={Mora3Path} alt="mora3"/>
            </div>
        </>);
}

export default function MoraProject() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<>
            <main>
                <Menu sameView={false}/>
                <ProjectSpace modelName={'mora'}/>
                <div className="introContainer">
                    <img className="project-logo" src={MoraLogoPath} alt="MORA logo"/>
                    <p>
                        Mora es un juego que busca motivar el aprendizaje
                        de matemáticas a través de la ciencia colectiva
                    </p>
                    <img className="main-project-img-vertical" src={AstronautMORA} alt="Astronauta MORA"/>
                </div>
                <section className="projectContainer">
                    <div className="projectContent">
                        <div className="summaryParagraphContainer">
                            <p className="summaryParagraph">
                                Mora es una aplicación orientada a estudiantes del primer ciclo de escuela primaria (6 a 8 años)
                                que incentiva la práctica de habilidades de aritmética básica a través de una
                                aproximación lúdica, usando distintas técnicas de gamification tanto en su narrativa como en su
                                interfaz visual.
                            </p>
                        </div>

                        <div className="collaboratorsContainer">
                            <MoraGallery/>
                            <CollaboratorBox>
                                <p className="collaborationBoxBaseText">
                                    Como nuestro fuerte se basa en el desarrollo de software buscamos
                                    conectarnos con personas y empresas para complementar los distintos conocimientos
                                    en comunicación, diseño y pedagogía.
                                </p>
                                <div className="collaborator">
                                    <p className="collaboratorParagraph">
                                        Sabri y la Colo
                                        <span className="collaboratorSpan">(Sin Patrón)</span>
                                    </p>
                                    <a className="grey collaboratorLink" href="https://www.sin-patron.com.ar/"
                                       target="_blank"> https://www.sin-patron.com.ar/</a>
                                </div>
                                <div className="collaborator">
                                    <p className="collaboratorParagraph">
                                        Juan Manuel Garrido
                                        <span className="collaboratorSpan">(El Gato y la Caja)</span>
                                    </p>
                                    <a className="grey collaboratorLink" href="https://elgatoylacaja.com/"
                                       target="_blank"> https://elgatoylacaja.com/</a>
                                </div>
                                <div className="collaborator">
                                    <p className="collaboratorParagraph">
                                        Andrés Rieznik, Valeria Edelstein, Leonardo Más
                                        <br></br>
                                        <span className="collaboratorSpan">(Equipo pedagógico)</span>
                                    </p>
                                </div>
                            </CollaboratorBox>
                        </div>

                        <p className="text-more-info">
                            Los desafíos principales del proyecto eran, por un lado, que contara una historia que fuera
                            visualmente atractiva. Por el otro, también debía tener
                            los contenidos matemáticos necesarios adaptados para el público objetivo. Como nuestro fuerte se
                            basa en el desarrollo de software buscamos
                            conectarnos con personas y empresas para complementar los distintos conocimientos en
                            comunicación, diseño y pedagogía.
                        </p>

                        <iframe width="100%" height="600" src="https://www.youtube.com/embed/X3okRNIdjSY" title="MORA"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>

                        <div className="links-container">
                            <div className="link-container">
                                <span>La Liga de la ciencia</span>
                                <a href="https://www.youtube.com/watch?v=EVeF4zNiCcA">youtube.com/watch?v=EVeF4zNiCcA</a>
                            </div>
                            <div className="link-container">
                                <span>El gato y la caja</span>
                                <a href="https://elgatoylacaja.com/mora">elgatoylacaja.com/mora</a>
                            </div>
                            <div className="link-container">
                                <span>Descargar MORA: Matemática para jugar</span>
                                <a href="https://play.google.com/store/apps/details?id=com.EryxLabs.Mora&hl=es_AR&gl=US">google-play/descargas</a>
                            </div>
                            <div className="link-container">
                                <span>Descargar MORA II</span>
                                <a href="https://play.google.com/store/apps/details?id=com.mora2&hl=es_AR&gl=US">google-play/descargas</a>
                            </div>
                            <div className="link-container">
                                <span>Aprender jugando</span>
                                <a href="https://medium.com/eryxcoop/aprender-jugando-6637960b4c4b">medium.com/eryxcoop/aprender-jugando</a>
                            </div>
                        </div>

                    </div>
                    <NextProjectBtn project={'aurora'}/>
                </section>
            </main>
        </>);
}