import {useLayoutEffect, useRef} from "react";
import {gsap} from "gsap";

export function ProjectIcon() {

    return (
        <svg className="iconProject" viewBox="0 0 31 31"  xmlns="http://www.w3.org/2000/svg">
            <g className="projectIconLayer1">
                <path class="st0" d="M15.3,20.2c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8S17.9,20.2,15.3,20.2z M15.3,11.7
		c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S17.4,11.7,15.3,11.7z"/>
                <path class="st0" d="M16.2,6.2C16,5.5,15.4,5,14.6,5C13.8,5,13,5.7,13,6.5c-4,1-6.9,4.6-6.9,8.9c0,2.4,1,4.7,2.5,6.3
		c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.9,0.8,1.7,1.7,1.7c0.4,0,0.8-0.2,1.2-0.5c1.2,0.6,2.6,1,4.1,1c5.1,0,9.2-4.1,9.2-9.3
		C24.6,10.7,20.9,6.7,16.2,6.2z M15.3,23.7c-1.3,0-2.5-0.3-3.6-0.8c0-0.1,0-0.2,0-0.4c0-0.9-0.8-1.7-1.7-1.7c-0.3,0-0.6,0.1-0.8,0.2
		c-1.4-1.5-2.2-3.4-2.2-5.6c0-3.8,2.6-7,6.1-8C13.4,8,14,8.4,14.6,8.4c0.7,0,1.4-0.5,1.6-1.1c4.1,0.5,7.3,4,7.3,8.2
		C23.6,20,19.9,23.7,15.3,23.7z"/>
                <circle class="st1" cx="15.3" cy="15.4" r="11.3"/>
            </g>
            <g className="projectIconLayer2">
                <path class="st0" d="M28.4,19.7c0.4-1.3,0.7-2.8,0.7-4.2c0-7.6-6.2-13.8-13.8-13.8c-5.2,0-9.8,2.9-12.1,7.2c-0.1,0-0.2,0-0.3,0
		c-0.9,0-1.7,0.8-1.7,1.7c0,0.6,0.3,1.1,0.8,1.4c-0.3,1.1-0.4,2.2-0.4,3.4c0,7.6,6.2,13.8,13.8,13.8c4.9,0,9.3-2.6,11.7-6.5
		c0.1,0,0.3,0,0.4,0c0.9,0,1.7-0.8,1.7-1.7C29.1,20.5,28.8,20,28.4,19.7z M15.3,28.2c-7,0-12.8-5.7-12.8-12.8c0-1.1,0.2-2.2,0.4-3.2
		c0.9-0.1,1.6-0.8,1.6-1.7c0-0.5-0.2-0.9-0.5-1.2c2.2-4,6.4-6.7,11.2-6.7c7,0,12.8,5.7,12.8,12.8c0,1.4-0.2,2.7-0.6,3.9
		c0,0,0,0-0.1,0c-0.9,0-1.7,0.8-1.7,1.7c0,0.4,0.2,0.8,0.4,1.1C23.9,25.8,19.9,28.2,15.3,28.2z"/>
                <circle class="st1" cx="15.3" cy="15.4" r="15.3"/>
            </g>
        </svg>

    );
}