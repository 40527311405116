import MoraImagePath from '../images/mora_menu.png';
import SSfImagePath from '../images/ssf_menu.png';
import AuroraImagePath from '../images/aurora_menu.png';
import FaduVivaImagePath from '../images/fadu_menu.png';
import {Link} from "react-router-dom";
import {CircleProjects} from "../svgs/CircleProjects";

const projectMenuStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        zIndex: 2,
    }
}

// Ver https://css-tricks.com/snippets/sass/placing-items-circle/

function ProjectOption(props) {
    return (
        <div className="project-option-container">
            <div className="project-image-container">
                <img className="project-image" src={props.imagePath} alt="..."/>
            </div>
            <Link className="project-name" to={props.link}>
                {props.name}
            </Link>
        </div>
    );
}

export default function ProjectsMenu() {
    return (
        <section id="project-menu" style={projectMenuStyle.container}>
            <CircleProjects/>
            <ProjectOption name="MORA" link={'/mora'} imagePath={MoraImagePath}/>
            <ProjectOption name="AURORA" link={'/aurora'} imagePath={AuroraImagePath}/>
            <ProjectOption name="FADU viva" link={'/fadu-viva'} imagePath={FaduVivaImagePath}/>
            <ProjectOption name="Sustentabilidad sin Fronteras" link={'/ssf'} imagePath={SSfImagePath}/>
        </section>
    );
}