export function MenuMobileIcon() {

    return (
        <svg className="iconContact" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="st0" d="M0,5.1v1.8h30.4V5.1H0z M0,16.2h30.4v-1.8H0V16.2z M0,25.5h30.4v-1.8H0V25.5z"/>
            <rect class="st1" width="30.4" height="30.6"/>
        </svg>

    );
}
