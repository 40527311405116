import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import './svgs/animationes.css';
import reportWebVitals from './reportWebVitals';
import AuroraProject from './projects/AuroraProject';
import Home from './views/Home';
import MoraProject from './projects/MoraProject';
import SSFProject from "./projects/SSFProject";
import FaduVivaProject from "./projects/FaduVivaProject";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/aurora",
        element: <AuroraProject/>,
    },
    {
        path: "/mora",
        element: <MoraProject/>,
    },
    {
        path: "/ssf",
        element: <SSFProject/>,
    },
    {
        path: "/fadu-viva",
        element: <FaduVivaProject/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
