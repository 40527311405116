export function ArrowRightIcon() {

    return (
        <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.47288e-07 1.33372L14.6662 15.9999L3.97502e-07 30.6662L1.33382 32L17.3339 15.9999L1.33382 -0.000102981L7.47288e-07 1.33372Z"
                  fill="#D9D9D9"/>
        </svg>

    );
}